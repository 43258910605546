<template>
  <svg width="1.3125rem" height="1.3125rem" viewBox="0 0 21 21" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path
      d="M8.13328 15.3369C12.0233 15.3369 15.1767 12.1834 15.1767 8.29343C15.1767 4.40345 12.0233 1.25 8.13328 1.25C4.2433 1.25 1.08984 4.40345 1.08984 8.29343C1.08984 12.1834 4.2433 15.3369 8.13328 15.3369Z"
      stroke="#000064"
      stroke-width="2"
      stroke-miterlimit="10"
    />
    <path
      d="M19.389 19.25L13.1797 13.2057"
      stroke="#000064"
      stroke-width="2"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>

<script>
export default {
  name: 'SearchIcon',
}
</script>
